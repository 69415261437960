export const environment = {
  production: false
};

export const urlProducts =
  {
    savingsAccount: 'https://digital.bancodebogota.com/cuenta-ahorros/index.html',
    creditCard : 'https://digital.bancodebogota.com/tarjeta-credito/index.html',
    homeLoan: 'https://digital.bancodebogota.com/vivienda/index.html',
    payrollCredit: 'https://digital.bancodebogota.com/libranza/index.html',
    consumerCredit: 'https://digital.bancodebogota.com/credito/index.html',
    CDT: 'https://digital.bancodebogota.com/cdt/index.html'

  };

export const URL_CONSUMER_CREDIT = {
  url: 'https://creditodigital.labdigitalbdbstaging.co/#/authenticator-handler'
}

export const ENV = {
  CYPHER_JS_KEY: 'CC_ALLIES_WEB_CIPHER_BDB_DEV',
};

export const ENDPOINT_API = {
  consumerCredit: 'https://api-staging.bancodebogota.co/credito/',
};

export const ALLY_MNGR_PATHS = {
  allyInfo: ENDPOINT_API.consumerCredit + 'ally/info',
  allyLogo: ENDPOINT_API.consumerCredit + 'ally/ally-logo'
};
